import React from 'react'
import Layout from '@/layout'
import { graphql } from 'gatsby'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Hero from '@/components/contents/AcademyIndex/Hero'
import Featured from '@/components/contents/AcademyIndex/Featured'
import Footer from '@/components/Partials/Footer/Footer'
import Prefooter from '@/components/Partials/Prefooter/Prefooter'
import getMetaImage from '@/utils/getMetaImage'

const AcademyIndexPage = ({ data }) => {
  const content = { ...data?.content?.data, slug: data?.content?.slug }

  const academy = data.academy.nodes

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <NavBar />
      <Hero content={content} />
      <Featured content={academy} />
      <Prefooter content={content} />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query AcademyIndex($locale: String) {
    content: prismicAcademyPage(lang: { eq: $locale }) {
      lang
      slug: uid
      data {
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        hero_title
        hero_description
        hero_duration_title
        hero_duration
        prefooter_cards {
          card {
            document {
              ... on PrismicPrefootercard {
                data {
                  title
                  description
                  type {
                    uid
                    type
                    url
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
    academy: allPrismicAcademy(filter: { lang: { eq: $locale } }) {
      nodes {
        data {
          meta_title
          meta_description
          meta_image {
            alt
            localFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  src
                  sizes
                  originalName
                  srcSet
                }
              }
            }
          }
          type
          type_description
          duration
          duration_value
          link_text
          link
          trainer
          trainer_name
          trainer_image {
            alt
            localFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  src
                  sizes
                  originalName
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AcademyIndexPage
