import React from 'react'
import * as UI from '@/components/UI'
import srcCheeto from '@/assets/images/cheetos-blue.png'
import { Section, Grid, Cell } from './Featured.css'
import AcademyCard from './AcademyCard'

const AcademyIndexFeatured = ({ content }) => {
  if (!content.length) return null

  return (
    <Section>
      <UI.Container>
        <UI.Parallax top='42%' right='20%' force={1.3} offsetComp={30}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={srcCheeto}
              alt={content.hero_duration_title}
              width='400'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <Grid>
          {content.map(x => (
            <Cell key={x.data.meta_title}>
              <AcademyCard featured={x.data} variant='academy' />
            </Cell>
          ))}
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default AcademyIndexFeatured
